<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItems" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import Logo from '@/assets/images/core/logo.png'
import Signet from '@/assets/images/core/sygnet.png'
import { mapGetters } from 'vuex'
import { GET_FUNNELS, GET_SETTINGS } from '@/@constants/mutations'
import axiosIns from '@/libs/axios'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  data: () => ({
    navMenuItems: [],
    appLogo: Logo,
    appSignet: Signet,
  }),
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
      currentUser: 'auth/getCurrentUser',
    }),
  },
  created() {
    this.$store.dispatch(`system/${GET_SETTINGS}`)
      .then(() => {
        // eslint-disable-next-line array-callback-return
        const preparedNavItems = navMenuItems.filter(route => this.routeFilter(route))
          .map(route => {
            const routeItem = route
            if (routeItem?.children?.length) {
              routeItem.children = routeItem.children.filter(childRoute => this.routeFilter(childRoute))
                .map(child => child)
              routeItem.children.forEach(k => {
                if (k?.children?.length) {
                  // eslint-disable-next-line
                  k.children = k.children.filter(childRoute => this.routeFilter(childRoute))
                    .map(child => child)
                }
              })
            }

            return routeItem
          })

        const token = localStorage.getItem('token')
        if (token && this.findFunnelIndex(preparedNavItems) >= 0) {
          axiosIns.defaults.headers.common.Authorization = `Bearer ${token}`
          this.$store.dispatch(`funnelMain/${GET_FUNNELS}`)
            .then(res => {
              res.forEach((funnel, index) => {
                preparedNavItems[this.findFunnelIndex(preparedNavItems)].children[index] = {
                  title: funnel.name,
                  route: {
                    name: 'sales-funnel',
                    params: { id: funnel.id },
                  },
                }
              })
            })
            .finally(() => {
              this.$set(this, 'navMenuItems', preparedNavItems)
            })
        } else {
          this.$set(this, 'navMenuItems', preparedNavItems)
        }
      })
  },
  methods: {
    routeFilter(route) {
      let allow = true
      if (route?.requiredModule?.length) {
        const settings = this.$store.getters['system/getSettings']
        const modules = route.requiredModule.filter(module => !!settings[module])
        allow = route.requiredModule.length === modules.length
        if (!allow) return false
      }

      if (!route?.requiredPermissions && route?.viewPermissions && allow) {
        return this.checkRequiredViewPermissions(route.viewPermissions)
      }

      if (route?.requiredPermissions && route?.viewPermissions && allow) {
        return this.checkRequiredPermissions(...route.requiredPermissions) && this.checkRequiredViewPermissions(...route.viewPermissions)
      }

      if (route?.requiredPermissions && allow) {
        return this.checkRequiredPermissions(...route.requiredPermissions)
      }

      return true
    },
    findFunnelIndex(array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[i].route === 'sales-funnel') {
          return i
        }
      }
      return -1
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
